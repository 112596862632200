<template>
  <div>
    <div class="operationControl searchboxfl">
      <div class="searchbox">
        <div title="班级名称" class="searchboxItem ci-full">
          <span class="itemLabel">班级名称:</span>
          <el-input
            v-model="projectName"
            type="text"
            size="small"
            placeholder="请输入班级名称"
            clearable
          />
        </div>
        <div title="负责人姓名" class="searchboxItem ci-full">
          <span class="itemLabel" style="width: 130px">负责人姓名:</span>
          <el-input
            v-model="projectUser"
            type="text"
            size="small"
            placeholder="请输入负责人姓名"
            clearable
          />
        </div>
        <div title="负责人电话" class="searchboxItem ci-full">
          <span class="itemLabel" style="width: 130px">负责人电话:</span>
          <el-input
            v-model="projectUserPhone"
            type="text"
            size="small"
            placeholder="请输入负责人电话"
            clearable
          />
        </div>
        <!-- <div title="机构名称" class="searchboxItem ci-full">
          <span class="itemLabel">机构名称:</span>
          <el-select
            size="small"
            v-model="compId"
            remote
            :remote-method="getCompanyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in CompanyList"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div> -->
      </div>
      <div class="searchbox" style="margin-top: 10px">
        <div title="行政区划" class="searchboxItem ci-full">
          <span class="itemLabel">行政区划:</span>
          <el-cascader
            v-model="areaId"
            :options="areatreeList"
            :props="propsarea"
            :disabled="disabled"
            clearable
            filterable
            size="small"
          ></el-cascader>
        </div>
        <span title="培训类型" class="searchboxItem ci-full flexcc">
          <span class="itemLabel" style="width: 130px">培训类型:</span>
          <tree
            ref="tree"
            @eventBtn="childBack"
            :ruleForm="ruleForm"
            size="small"
            @clearParams="clearParams"
            modal
            typeStu
          />
        </span>
        <span title="班级编码" class="searchboxItem ci-full flexcc">
          <span class="itemLabel" style="width: 130px">班级编码:</span>
          <el-input
            v-model="projectCode"
            type="text"
            size="small"
            placeholder="请输入班级编码"
            clearable
          />
        </span>
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            fixed
            width="100px"
          />
          <el-table-column
            label="班级名称"
            align="left"
            show-overflow-tooltip
            prop="liveProjectName"
            width="200"
            fixed
          />  
          <el-table-column
            label="班级编码"
            align="left"
            show-overflow-tooltip
            prop="projectCode"
            width="200"
            fixed
          />
          <el-table-column
            label="所属期次"
            align="left"
            show-overflow-tooltip
            prop="periodName"
            width="140"
          />
          <el-table-column
            label="行政区划"
            align="left"
            show-overflow-tooltip
            prop="areaName"
            width="150"
          />
          <el-table-column
            label="培训类型"
            align="left"
            show-overflow-tooltip
            prop="trainTypeNamePath"
            width="120"
          />
          <el-table-column
            label="岗位类型"
            align="left"
            show-overflow-tooltip
            width="120"
          >
            <template slot-scope="scope">{{
              scope.row.postName || "--"
            }}</template>
          </el-table-column>
          <el-table-column
            label="行业类型"
            align="left"
            show-overflow-tooltip
            width="120"
          >
            <template slot-scope="scope">{{
              scope.row.industryNamePath || "--"
            }}</template>
          </el-table-column>
          <el-table-column
            label="职业/工种"
            align="left"
            show-overflow-tooltip
            width="120"
          >
            <template slot-scope="scope">{{
              scope.row.occupationNamePath || "--"
            }}</template>
          </el-table-column>
          <el-table-column
            label="培训等级"
            align="left"
            show-overflow-tooltip
            width="120"
          >
            <template slot-scope="scope">{{
              scope.row.trainLevelName || "--"
            }}</template>
          </el-table-column>
          <el-table-column
            label="负责人"
            align="left"
            show-overflow-tooltip
            prop="projectUser"
          />
          <el-table-column
            label="负责人电话"
            width="120"
            align="left"
            show-overflow-tooltip
            prop="projectUserPhone"
          />
          <el-table-column
            label="提交机构"
            align="left"
            show-overflow-tooltip
            prop="compName"
            width="200"
          />
          <el-table-column
            label="提交审核时间"
            align="left"
            show-overflow-tooltip
            prop="submitTime"
            width="140"
            :sort-orders="['ascending', 'descending']"
            sortable="custom"
          >
            <template slot-scope="scope">{{
              scope.row.submitTime | moment
            }}</template>
          </el-table-column>
          <el-table-column
            label="审核人"
            align="left"
            show-overflow-tooltip
            prop="auditAdminName"
            v-if="name == 'second' || name == 'three'"
          />
          <el-table-column
            label="审核时间"
            align="left"
            show-overflow-tooltip
            width="200"
            prop="auditTime"
            v-if="name == 'second' || name == 'three'"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.auditTime | moment }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="130px"
            show-overflow-tooltip
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="
                  doReview(
                    scope.row.liveProjectId)
                "
                >详情</el-button
              >

              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                v-show="name !== 'second'"
                @click="
                  projectPass(
                    scope.row.liveProjectId,
                    name
                  )
                "
                >通过</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                v-show="name !== 'three'"
                @click="reject(scope.row.liveProjectId, name)"
                >驳回</el-button
              >
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog
      title="审核事由"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <el-input
          type="textarea"
          placeholder="请输入审核理由"
          maxlength="100"
          show-word-limit
          v-model="auditReason"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle" class="bgc-bv">取 消</el-button>
        <el-button @click="sure()" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "CourseToBeReviewed",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      CompanyList: [],
      compId: "",
      names: "",
      projectName: "", // 班级名称
      projectUser: "", // 负责人姓名
      projectUserPhone: "", // 负责人电话
      areaId: "", // 行政区划
      projectCode: "", // 班级编码
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      dialogVisible: false,
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
      resourceId: "",
      auditReason: "",
      currentAuditState: "",
      auditTimeStort: "",
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
    this.getareatree();
    this.ruleForm.Trainingtype = "";
  },
  methods: {
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum, url, name) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // 班级名称
      if (this.projectName) {
        params.liveProjectName = this.projectName;
      }
      // 负责人姓名
      if (this.projectUser) {
        params.projectUser = this.projectUser;
      }
      // 负责人电话
      if (this.projectUserPhone) {
        params.projectUserPhone = this.projectUserPhone;
      }
      // 行政区划
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      // 培训类型
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      // 班级编码
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      url = "/liveProject/live-project-audit/pageList";
      if (!name) {
        let routerName = this.$route.query.active;      
        if (routerName == "second") {
          params.auditState = 30;
        } else if (routerName == "three") {
          params.auditState = 40;
        } else {
          params.auditState = 20;
        }
        this.name = this.$route.query.active || "first";
      } else {
        if (name == "second") {
          params.auditState = 30;
        } else if (name == "three") {
          params.auditState = 40;
        } else {
          params.auditState = 20;
        }
      }
      this.doFetch({
        url: url,
        params,
        pageNum,
      },true,3);
    },
    handleListDataBack(data){
      this.tableData=[]
      data.forEach(el=>{
        this.tableData.push(el)
      })
    },
    // 详情
    doReview(liveProjectId) {
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassDetails",
        query: {
          getClassInfo: JSON.stringify({
            liveProjectId,
            status: this.name,
          }),
        },
      });
    },
    // 审核通过
    projectPass(liveProjectId, name) {
      const parmar = {
        liveProjectId,
      };
      this.$post("/liveProject/live-project-audit/pass", parmar, 3000, true, 3)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: "审核成功",
            });
            this.getData(-1, "/liveProject/live-project-audit/pageList", name);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 驳回
    reject(liveProjectId, name) {
      this.liveProjectId = liveProjectId;
      this.dialogVisible = true;
      this.name = name;
    },
    // 确定驳回
    sure() {
      if (!this.auditReason) {
        this.$message({
          message: "请输入驳回理由",
          type: "warning",
        });
        return false;
      }
      const parmar = {
        liveProjectId: this.liveProjectId,
        auditReason: this.auditReason,
      };
      this.$post("/liveProject/live-project-audit/reject", parmar,3000,true,3)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: "成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.auditReason = "";
            this.getData(1, "/liveProject/live-project-audit/pageList", this.name);
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.auditReason = "";
      this.dialogVisible = false;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
  },
  mounted: function () {},
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
    compId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
<style lang="less">
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>