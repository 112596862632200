<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">审核管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">直播班级审核</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs
          v-model="activeName"
          :before-leave="beforeTabLeave"
          @tab-click="tabsChange"
        ><el-tab-pane label="待审核" name="first">
            <liveClassToBeReviewed :name="activeName" ref="first" @get="clearParams"/>
          </el-tab-pane>
          <el-tab-pane label="已通过" name="second">
            <liveClassToBeReviewed :name="activeName" ref="second" />
          </el-tab-pane>
          <el-tab-pane label="已驳回" name="three">
            <liveClassToBeReviewed :name="activeName" ref="three" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>  
</template>
<script>
import List from "@/mixins/List";
import liveClassToBeReviewed from "@/views/liveBroadcastManagement/liveClassReview/liveClassToBeReviewed";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "liveClassReview",
  components: {
    liveClassToBeReviewed,
  },
  mixins: [List],
  data() {
    return {
      activeName: "",
    };
  },
  created() {
    this.activeName = this.$route.query.active || "first";
  },
  mounted() {
    this.$refs[this.activeName].getData(
      -1,
      "/liveProject/live-project-audit/pageList",
      this.$route.query.active
    );
  },
  methods: {
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName },
      });
    },
    tabsChange(e) {
      this.activeName = e.name;
      this.name = e.name;
      this.$refs[e.name].getData(1, "/biz/live/course/auditPassedList", e.name);
      this.$refs[e.name].ruleForm.Trainingtype = "";
      this.$refs[e.name].params = {};
      this.$refs[e.name].projectName = "";
      this.$refs[e.name].areaId = "";
      this.$refs[e.name].projectUser = "";
      this.$refs[e.name].projectUserPhone = "";
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: function (route) {
      if (route.query.refresh) {
        this.$refs[route.query.active].getData(-1);
      }
    },
  },
};
</script>
<style lang="less">
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
    background-color: #999; //设置白色背景色
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
